import React, { useState, useEffect, useRef } from "react";
import { Button } from "../../components/Button";
import { Quote } from "../../components/Quote";
import { Avatar } from "../../icons/Avatar";
import { IconsCloudLaptop } from "../../icons/IconsCloudLaptop";
import { IconsHouse1 } from "../../icons/IconsHouse1";
import { IconsHouse2 } from "../../icons/IconsHouse2";
import { IconsPhone } from "../../icons/IconsPhone";
import { IconsPlug1 } from "../../icons/IconsPlug1";
import "./style.css";
import { IconsMill } from "../../icons/IconsMill";
import Worker from "../../assets/worker.png";
import Placeholder from "../../assets/placeholder.png";
import TrailbluVideo from "../../assets/trailbluvideo.mp4";
import Fibabanka from "../../assets/coloredFibaBanka.png";
import Aktifbank from "../../assets/coloredAktifBank.png";
import Osigo from "../../assets/osigoLogo.png";
import Imece from "../../assets/imeceLogo.png";
import LogoCompany from "../../assets/coloredLogo.png";
import TurkiyeSigorta from "../../assets/turkiyesigorta.png";
import MobileTesting from "../../assets/mobile-web-app-testing.svg";
import DataDrivenTesting from "../../assets/data-driven-testing.svg";
import VisualTesting from "../../assets/visual-testing.svg";
import Frame171 from "../../assets/frame-17-1.svg";
import Frame17 from "../../assets/frame-17.svg";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer.jsx";
import Capterra from "../../assets/capterra.svg";
import GetApp from "../../assets/getApp.png";
import { Helmet } from "react-helmet-async";
import TrailbluIntro from "../../assets/TrailbluIntro.png";



export const Main = () => {

  useEffect(() => {
    const scrollers = document.querySelectorAll("#scroller");
  
    
    if (!window.matchMedia("(prefers-reduced-motion: reduce)").matches) {
      addAnimation();
    }
  
    function addAnimation() {
      scrollers.forEach(scroller => {
        scroller.setAttribute('data-animated', true);

        const scrollerInner = scroller.querySelector('.scroller-inner');
        const scrollerContent = Array.from(scrollerInner.children);
      
        scrollerContent.forEach(item => {
          const duplicatedItem = item.cloneNode(true);
          duplicatedItem.setAttribute('aria-hidden', true);
          scrollerInner.appendChild(duplicatedItem);
        })
      });
    }
  }, []);

  const [menuVisible, setMenuVisible] = useState(false);

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

 

  const handleMenuItemClick = () => {
    
    setMenuVisible(false);
  };

//-----------------
  const videoRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    // Check if the video element is available in the DOM
    if (isModalOpen && videoRef.current) {
      const playVideo = async () => {
        try {
          await videoRef.current.play();
        } catch (error) {
          console.error("Error playing the video:", error);
        }
      };

      playVideo();
    }

    // Cleanup function to pause and reset video when modal closes or component unmounts
    return () => {
      if (videoRef.current) {
        videoRef.current.pause();
        videoRef.current.currentTime = 0;
      }
    };
  }, [isModalOpen]);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <div className="MAIN">
      <Helmet>
        <title>AI-Powered, No-Code Test Automation | TRAILBLU</title>
        <meta
          name="description"
          content="TRAILBLU: No-Code
          Testing, AI-Powered
          Crawler, Self-Healing, Visual
          Testing, and DevOps
          integration. Effortlessly
          transform mobile and web
          app testing.
          "
        />
        <meta
          name="twitter:title"
          content="Empower Seamless Testing: TRAILBLU's AI-driven No-Code Testing Solution"
        />
        <meta
          name="twitter:description"
          content="Explore TRAILBLU, the pinnacle of no-code testing empowered by AI. Revolutionize your testing journey effortlessly with our cutting-edge platform."
        />
        <meta name="twitter:image" content={TrailbluIntro} />
        <meta name="twitter:image:alt" content="TRAILBLU Image" />
        <meta name="twitter:card" content="	summary_large_image" />
        <meta name="twitter:site" content="@trailblu" />
        <meta
          property="og:title"
          content="Trailblu - AI-Powered Test Automation"
        />
        <meta property="og:site_name" content="Trailblu" />
        <meta property="og:url" content="https://www.trailblu.com/" />
        <meta
          property="og:description"
          content="TRAILBLU is an AI-powered test automation tool that helps you test your mobile and web apps in minutes – without a single line of code. We test your app for you, so you can spend time building instead of fixing."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://www.trailblu.com/worker.2a57633e.png"
        />

        <meta property="og:image:alt" content="TRAILBLU Image" />

        {/* Other meta tags can be added similarly */}
      </Helmet>
      <Header />
      <div className="frame-wrapper">
        <div className="div-2">
          <div className="div-wrapper">
            <div className="frame-4">
              <h1 className="text-wrapper-3">
                <span className="new-line-2">
                  <span class="highlight-container">
                    <span class="highlight">Generative AI-Powered,</span>{" "}
                  </span>
                </span>
                <span className="new-line-2">No-Code Test Automation </span>
                {/*<div className="automation-text">
                  <span className="new-line">Test</span>
                  <span className="new-line">Automation</span>
                </div>
                */}
              </h1>
              <p className="p">
                TRAILBLU is a Generative AI-Powered test automation tool that
                helps you test your mobile and web apps in minutes – without a
                single line of code. We test your app for you, so you can spend
                time building instead of fixing.
              </p>
              <a href="/request-a-demo">
                <Button
                  className="button-2"
                  stateProp="normal"
                  style="solid"
                  text="Get started"
                  type="regular"
                />
              </a>
              {/* <a href="#section7">
                <Button className="button-2" stateProp="normal" style="solid" text="Get started" type="regular" />
              </a> */}
            </div>
          </div>
          {/* <div className="img-wrapper">
            <img className="worker" alt="Trailblu Worker Image" src={Worker} />
          </div> */}
          {/* <div className="video-wrapper">
            <video className="worker-video" controls preload="none" poster={Placeholder}>
                <source src={TrailbluVideo}  type="video/mp4"/>
                Your browser does not support the video tag.
            </video>
         </div> */}
      {isModalOpen && (
        <div className="modal" onClick={() => setIsModalOpen(false)}>
          <video ref={videoRef} className="modal-video" controls preload="auto" poster={Placeholder} onClick={e => e.stopPropagation()}>
            <source src={TrailbluVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      )}

      {!isModalOpen && (
        <div className="placeholder" onClick={toggleModal}>
          <img src={Placeholder} alt="Click to play video" style={{ width: '100%', cursor: 'pointer' }} />
          <button className="play-button">Play</button>
        </div>
      )}
        </div>
      </div>
      <div className="frame-5">
        <div className="frame-6">
          <div className="text-wrapper-4">Trusted by</div>
          <div className="frame-7">
            <div class="scroller" id="scroller">
              <div class="scroller-inner">
            <div className="img-wrapper2">
              <img className="companies" alt="Fibabanka" src={Fibabanka} />
            </div>
            <div className="img-wrapper2">
              <img className="companies" alt="Osigo" src={Osigo} />
            </div>
            <div className="img-wrapper2">
              <img className="companies" alt="TurkiyeSigorta" src={TurkiyeSigorta}  />
            </div>
            <div className="img-wrapper2">
              <img className="companies" alt="Aktifbank" src={Aktifbank} />
            </div>
            <div className="img-wrapper2">
              <img className="companies" alt="Imece" src={Imece}  />
            </div>
            <div className="img-wrapper2">
              <img className="companies" alt="LogoCompany" src={LogoCompany}  />
            </div>
            </div>
            </div>
          </div>
        </div>
      </div>
      <div className="frame-8">
        <div className="text-wrapper-5">Great features</div>

        <div className="frame-9-copy">
          <div className="CARD-2-copy">
            <div className="frame-10">
              <h2 className="starter">Generative AI-Powered Test Creation</h2>
              {/* <IconsCloudLaptop className="icon-instance-node" /> */}
              <img
                className="icon-instance-node-2"
                alt="Trailblu mobile testing"
                src={MobileTesting}
              />
            </div>
            <div className="frame-32-copy">
              <p className="automate-mobile-and-2">
                Embrace the future of testing with our advanced GPT technology,
                ensuring thorough coverage and reliability in every aspect of
                your application.
              </p>
              <a
                href="/feature/generative-ai-powered-test-creation"
                style={{
                  width: "100%",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <Button
                  className="design-component-instance-node-2"
                  stateProp="normal"
                  style="outline"
                  text="Read more"
                  type="regular"
                />
              </a>
            </div>
          </div>
          <div className="CARD-2-copy">
            <div className="frame-10">
              <h2 className="starter">AI-Powered No-Code Testing</h2>
              <IconsCloudLaptop className="icon-instance-node" />
            </div>
            <div className="frame-32-copy">
              <p className="automate-mobile-and-2">
                Forget about tedious, time-consuming test writing. With No-Code
                Testing, you can quickly write tests and get fast insights.
              </p>
              <a
                href="/feature/ai-powered-no-code-test-automation"
                style={{ width: "100%" }}
              >
                <Button
                  className="design-component-instance-node-2"
                  stateProp="normal"
                  style="outline"
                  text="Read more"
                  type="regular"
                />
              </a>
            </div>
          </div>
          <div className="CARD-2-copy">
            <div className="frame-10">
              <h2 className="starter">AI-Powered Visual Testing</h2>
              <IconsPhone className="icon-instance-node" />
            </div>
            <div className="frame-32-copy">
              <p className="automate-mobile-and-2">
                Harness the power of AI to transform your visual quality
                assurance. Our tool provides comprehensive coverage, intuitive
                insights, and smart detection of even the most subtle visual
                issues.
              </p>
              <a
                href="/feature/ai-powered-visual-testing"
                style={{ width: "100%" }}
              >
                <Button
                  className="design-component-instance-node-2"
                  stateProp="normal"
                  style="outline"
                  text="Read more"
                  type="regular"
                />
              </a>
            </div>
          </div>
          <div className="CARD-2-copy">
            <div className="frame-10">
              <h2 className="starter">AI-Powered Self-Healing</h2>
              <IconsPlug1 className="icon-instance-node" />
            </div>
            <div className="frame-32-copy">
              <p className="automate-mobile-and-2">
                With TRAILBLU&#39;s AI-powered self-healing, you can get more
                from your existing tests by automatically identifying and fixing
                flaky tests.
              </p>
              <a
                href="/feature/ai-powered-self-healing"
                style={{ width: "100%" }}
              >
                <Button
                  className="design-component-instance-node-2"
                  stateProp="normal"
                  style="outline"
                  text="Read more"
                  type="regular"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="frame-12">
        <div className="frame-13">
          <div className="frame-14">
            <p className="text-wrapper-6">
              You’re here because you want the best
            </p>
            <div className="text-wrapper-7">And we know it</div>
          </div>
          <div className="frame-15">
            <div className="CARD-4">
              <img
                className="icon-instance-node-2"
                alt="Trailblu mobile testing"
                src={MobileTesting}
              />
              <div className="frame-16">
                <h3 className="text-wrapper-8">Mobile and Web App Testing</h3>
                <p className="text-wrapper-9">
                  Quickly test different layouts &amp; designs of your mobile or
                  web app in the cloud from anywhere.
                </p>
              </div>
            </div>
            <div className="CARD-4">
              <img
                className="icon-instance-node-2"
                alt="Trailblu laptop globe"
                src={DataDrivenTesting}
              />
              <div className="frame-16">
                <h3 className="text-wrapper-8">Data-driven Testing</h3>
                <p className="text-wrapper-9">
                  Speed up your testing and save time with TRAILBLU that lets
                  you easily connect your test data with test cases.
                </p>
              </div>
            </div>
            <div className="CARD-4">
              <img
                className="icon-instance-node-2"
                alt="Trailblu mobile feed"
                src={VisualTesting}
              />
              <div className="frame-16">
                <h3 className="text-wrapper-8">API Testing</h3>
                <p className="text-wrapper-9">
                  Effortlessly validate and optimize your APIs for seamless
                  integration and performance, ensuring robust communication
                  between your services with TRAILBLU's automated API testing.
                </p>
              </div>
            </div>
            <div className="CARD-4">
              <img
                className="icon-instance-node-2"
                alt="Trailblu mobile testing"
                src={MobileTesting}
              />
              <div className="frame-16">
                <h3 className="text-wrapper-8">
                Seamless DevOps and CI/CD Integrations
                </h3>
                <p className="text-wrapper-9">
                Simplify your workflow with drag-and-drop integrations for leading CI/CD tools like Jenkins and Azure DevOps, along with popular bug tracking platforms such as Jira. Effortlessly connect your pipelines to automate testing, track issues, and accelerate development cycles—saving you time and enhancing team collaboration.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="frame-8">
        <div className="frame-17">
          <div className="text-wrapper-5">Our clients love us!</div>
          <p className="text-wrapper-7">Look at what they say about us</p>
        </div>
        <div className="frame-18" style={{ flexDirection: "column" }}>
          <div className="quote-wrapper">
            <Quote
              className="quote-instance"
              divClassName="quote-2"
              frame={Frame171}
            />
            <Quote
              className="quote-instance"
              divClassName="quote-2"
              frame={Frame17}
              icon={<Avatar className="property-1-male" />}
              text="If you can right click with your mouse, you can write tests with TRAILBLU. It is that simple. TRAILBLU empowers us to get measurable results quickly and easily."
              text1="Erdem Oguz"
              text2="Test and R&amp;D Director, Aktifbank"
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "64px",
              paddingRight: "40px",
            }}
          >
            <a
              className="capterra-2"
              target="_blank"
              href="https://www.capterra.com/p/10014205/TRAILBLU/#reviews"
            >
              <img
                src={Capterra}
                alt="Trailblu Capterra"
                style={{ borderRadius: "5%", display: "flex", width: "100%" }}
              />
            </a>
            <a
              className="getapp-2"
              target="_blank"
              href="https://www.getapp.com/it-management-software/a/trailblu/reviews/"
            >
              <img
                className="getapp-img-2"
                src={GetApp}
                alt="Trailblu GetApp"
              />
            </a>
          </div>
        </div>
      </div>
      <div className="frame-19">
        <div className="frame-20">
          <div className="text-wrapper-10">Get started</div>
          <p className="text-wrapper-11">Choose one of our plans</p>
        </div>
        <div className="frame-21">
          <div className="CARD-5">
            <div className="frame-10">
              <div className="starter">Starter</div>
              <IconsMill className="icon-instance-node" />
            </div>
            <div className="frame-32">
              <p className="automate-mobile-and-3">
                <span className="text-wrapper-12">Automate</span>
                <span className="text-wrapper-13">
                  {" "}
                  mobile and web app testing
                  <br />
                </span>
              </p>
              <a href="/request-a-demo">
                <Button
                  className="design-component-instance-node-2"
                  stateProp="normal"
                  style="solid"
                  text="Request a demo"
                  type="regular"
                />
              </a>
              {/* <a href="#section7" style={{ width: "100%" }}>
                <Button
                  className="design-component-instance-node-2"
                  stateProp="normal"
                  style="solid"
                  text="Request a demo"
                  type="regular"
                  width="100%"
                />
              </a> */}
            </div>
          </div>
          <div className="CARD-5">
            <div className="frame-10">
              <div className="starter">Business</div>
              <IconsHouse1 className="icon-instance-node" />
            </div>
            <div className="frame-32">
              <p className="automate-mobile-and-3">
                <span className="text-wrapper-12">Automate</span>
                <span className="text-wrapper-13">
                  {" "}
                  mobile and web app testing
                  <br />
                </span>
                <span className="text-wrapper-12">Professional features</span>
                <span className="text-wrapper-13"> for remote teams</span>
              </p>

              <a href="/request-a-demo">
                <Button
                  className="design-component-instance-node-2"
                  stateProp="normal"
                  style="solid"
                  text="Request a demo"
                  type="regular"
                />
              </a>

              {/* <a href="#section7" style={{ width: "100%" }}>
                <Button
                  className="design-component-instance-node-2"
                  stateProp="normal"
                  style="solid"
                  text="Request a demo"
                  type="regular"
                />
              </a> */}
            </div>
          </div>
          <div className="CARD-5">
            <div className="frame-10">
              <div className="starter">Enterprise</div>
              <IconsHouse2 className="icon-instance-node" />
            </div>
            <div className="frame-32">
              <p className="automate-mobile-and-3">
                <span className="text-wrapper-12">Automate</span>
                <span className="text-wrapper-13">
                  {" "}
                  mobile and web app testing
                  <br />
                </span>
                <span className="text-wrapper-12">Professional features</span>
                <span className="text-wrapper-13">
                  {" "}
                  for remote teams
                  <br />
                </span>
                <span className="text-wrapper-12">Advanced features</span>
                <span className="text-wrapper-13">
                  {" "}
                  for large teams, priority support{" "}
                </span>
              </p>

              <a href="/request-a-demo">
                <Button
                  className="design-component-instance-node-2"
                  stateProp="normal"
                  style="solid"
                  text="Request a demo"
                  type="regular"
                />
              </a>

              {/* <a href="#section7" style={{ width: "100%" }}>
                <Button
                  className="design-component-instance-node-2"
                  stateProp="normal"
                  style="solid"
                  text="Request a demo"
                  type="regular"
                />
              </a> */}
            </div>
          </div>
        </div>
      </div>
      {/* <GoogleReCaptchaProvider reCaptchaKey="6Lebh0kmAAAAADBJ2_lCpmVKMTNtAKVmjCbn3bfv">
        <Form />
      </GoogleReCaptchaProvider>*/}
      <Footer />
    </div>
  );
};

export default Main;
